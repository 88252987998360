@tab-bar-height: 35px;

/* remove the glowing border on the search box in the navbar */
#navbar-search {
	&, &:active, &:focus {
		background-color: transparent;
		border: 0;
		color: white;
		box-shadow: none;
	}
}

.tabs-menu {
	padding-bottom: 40px;
}

.tabs-menu .nav-tabs-footer {
	display: block;
	background-color: @color-nav-primary;
	overflow: hidden;
	height: @tab-bar-height;
}

.nav-tabs {
	border-bottom: 0;
	float: left;
	padding-bottom: 20px;

	& > li {
		& > a {
			height: @tab-bar-height;
			line-height: @tab-bar-height;
			padding: 0 35px;
			margin: 0;
			outline: none;

			@media screen and (max-width: 500px) {
				padding: 0 15px;
			}
		}

		&.pull-right > a {
			width: 480px;
		}

		&.active {
			&, & a {
				background-color: transparent;
			}

			& > ul > li a {
				background-color: @color-nav-primary;
			}

			& a:hover {
				background-color: @color-green;
			}
		}
	}

	& li {
		& ul li.active {
			&, & a, & a:active, & a:visited {
				background-color: @color-green;
			}
		}

		& a, & a:focus {
			background-color: @color-nav-primary;
		}

		& a:hover {
			background-color: @color-green;
		}
	}
}

.nav-tabs li a,
.nav-tabs li.active a,
.nav-tabs > li.active a,
.nav-tabs > li.active a:hover,
.nav-tabs > li.active,
.nav-tabs li.active a:hover,
.nav-tabs li a:hover {
	color: #fff !important;
	text-transform: uppercase;
	border: 0 !important;
	border-radius: 0;
}

.nav-tabs li.active a:visited,
.nav-tabs li.active a:active,
.nav-tabs li.active a:focus {
	background-color: transparent;
}

.navbar {
	border: 0;
	margin-bottom: 0;
}

.account-menu .dropdown-menu {
	background-color: #fff !important;
	border-top-left-radius: 4px !important;
	box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px 0px !important;
	left: auto;
	position: absolute !important;
	right: 0;

	.divider {
		background-color: #E5E5E5 !important;
	}

	li a {
		color: #000 !important;

		&:hover {
			background-color: #efefef !important;
		}
	}
}

.navbar-inverse {
	background-color: #222;
	background-image: none;
	border-color: #080808;

	& .navbar-nav > li > a {
		color: #eee;

		&:hover {
			background-color: @color-green;
		}
	}
}

.navbar-right {
	padding-right: 15px;
}

.list-group-item {
	border-left: 0;
	border-right: 0;

	&:first-child {
		border-radius: 0;
		border-top: 0;
	}

	&:last-child {
		border-radius: 0;
	}
}

/* Dropdown Button */
.dropbtn {
	background-color: #4CAF50;
	color: white;
	padding: 16px;
	border: none;
	cursor: default;

	&[href] {
		cursor: pointer;
	}

	&:hover, &:focus {
		background-color: #3e8e41;
	}
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
	position: relative;
	display: inline-block;
}

/* Dropdown Alarms Menu (Hidden by Default) */
.dropdown-menu {
	background-color: #222;
}
.dropdown-menu>.active>a,
.dropdown-menu>li>a:hover,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:visited {
	background-image: none;
}


.dropdown-menu>li,
.dropdown-menu>li>a {
	height: 35px;
	line-height: 35px;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
	display: block;
}
