.view-control-panel-sites-edit {
    .tilebox {
        border: 1px @color-gray solid;
        cursor: grab;
        margin: 20px 0;
        padding: 15px 0;
        text-align: center;

        .pumptile {
            border: 1px @color-green solid;
            display: inline-block;
            font-size: 1rem;
            margin: 5px;
            overflow: hidden;

            &:hover:not(.selected) {
                opacity: 0.6;
            }

            &.selected {
                cursor: grabbing;
                box-shadow: 0 0 0 3px @color-green;
            }
        }

        .tile-label {
            top: 25%;
        }

        .tilebox-delete {
            border-color: @color-red;
            color: @color-red;

            .tile-label {
                color: inherit;
            }

            &.selected {
                box-shadow: 0 0 0 3px @color-red;
            }
        }
    }

    .tilemap {
        cursor: pointer;
    }

    .tile-detail-button {
        color: white;
        font-size: 1rem;
        left: 2px;
        opacity: 0;
        position: absolute;
        top: 2px;
        transition: opacity 100ms;
        z-index: 100;

        &:hover {
            opacity: 1 !important;
        }
    }

    .pumptile:hover .tile-detail-button {
        opacity: 0.3;
    }

    .popover {
        .popover-title {
            color: darken(@color-green, 15%);
            font-size: 1.1em;
            font-weight: bold;
            padding-right: 32px;
        }

        .popover-content {
            color: black;
            position: relative;

            .close {
                background: @color-gray;
                border-radius: 3px;
                position: absolute;
                top: -32px;
                right: 3px;
                padding: 0 5px;
            }

            .save {
                margin: 1em 0;
                padding: 0.2em;
                .pull-right();
                width: auto;
            }
        }
    }   
}
