#y_axis {
	width: 50px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
}
#chart {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 40px;
	right: 0;
}
#chart_container {
	position: relative;
	margin-left: 32px;
}
#graph .x_tick {
	color: white;
	bottom: -25px;
	border-left-color: grey;
}
.chart-controls .btn-lg {
	width: 100%;
}
text.y.label {
	font-size: 14px;
	padding: 100px;
}
div.x_label {
	color: black;
}

button.chart-button {
	background-color: @color-green;
	border: 3px;
	border-color: #FFFFFF;
	border-style: solid;
}

#show-all-button {
	background-color: @color-green;
	border: 3px;
	border-color: #FFFFFF;
	border-style: solid;
}

/* Slider
----------------------------------*/
#date-range-label {
	float:left;
	clear: both;
}

.ui-slider .ui-slider-range {
	position: absolute;
	z-index: 1;
	font-size: .7em;
	display: block;
	border: 0;
	background-position: 0 0;
	background: @color-green;
}

.ui-widget-content {
	border: 1px solid #95BD4E !important;
	background: none !important;
}

.ui-state-default, .ui-widget-header .ui-state-default {
	border: 1px solid #FFFFFF;
	background: #95BD4E repeat-x;
}

#amount-max, #amount-min {
	color: @color-green;
	font-weight: bold;
	background: transparent;
	border: 0px;
	font-size: 20px;
	margin-left: auto;
	margin-right: auto;
	width: 140px;
	padding: 10px;

	&:focus {
		border: none;
		box-shadow: none;
	}
}
