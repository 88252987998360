.btn {
	background: @color-dark-gray;
	margin: 0 16px;
	padding: 14px;
	color: #fff;
	border: 2px solid @color-green;
	letter-spacing: 1px;
	min-width: 52px;
}
.btn:hover {
  color: #fff;
  background-color: @color-green;
}

.btn:hover :not(.disabled):not(span) {
	background: @color-green;
	color: #fff;
	border: 2px solid #fff;
}
.btn:active, .btn:focus {
	border: 2px solid @color-green;
	outline: none !important;
}

.btn-default.disabled {
  background-color: inherit;
  background-image: none;
}

.btn-default {
  text-shadow: none;
}

.btn-default:hover {
  border-color: @color-green;
  background-color: @color-green;;
}

.fixed-width {
	min-width: 72px;
}
.fixed-width.long-text {
	font-size: 15px;
	padding-left: 5px;
	padding-right: 5px;
}

.btn-primary, .btn-primary:active, .btn-primary:focus {
  background-color: #393536;
}

.btn-primary:hover {
  border-color: @color-green;
}

.btn-inactive {
	background-color: #4f4c4c;
	border-color: #918f90;
	color: #918f90;
}

.btn-input-with-unit {
	display: inline-block;
	padding: 13px 0;
	height: 51px;
	max-width: 130px;

	input {
		max-width: 4em;
		text-align: right;
		background: none;
		border: none;
		float: left;
	}

	input:focus {
		border: none;
		outline: none;
		box-shadow: none;
	}

	.units {
		float: left;
		padding-left: 1em;
		padding-right: 1em;
		padding-top: 1px;
	}
}

.btn-short {
	padding: 0px;
	height: 35px !important;
}

@media screen and (max-width: 400px) {
	.modal-buttons .btn {
		display: block;
		margin-top: 1em;
		margin-left: auto;
		margin-right: auto;
	}
}

.btn-danger:focus, .btn-danger:hover {
  background-color: #c12e2a !important;
  background-position: 0 -15px;
}