.progress {
	margin-top: 12px;
	margin-bottom: 12px;
}

.modal-header {
	border: none;
	border-bottom: 2px solid @color-green;
	min-height: 48px;
	background-color: rgb(34, 34, 34);

	h4.modal-title {
	   color: white;
	   font-weight: normal;
   }
}

.modal-buttons > .btn {
	margin: 0;
}

.modal-body.modal-chart {
	background-color: white;
	height: 615px;
	padding: 0;
}

.modal-content {
	color: #fff;
	border: 2px solid @color-green;
	border-radius: 0px;
	background: url("/assets/images/background_square.png") repeat;

	h4 {
		color: @color-green;
		font-size: 16px;
	}
 	.close {
		width: 22px;
		height: 22px;
		border: none;
		opacity: 1;
		color: #fff;
		font-weight: normal;
	}
	.modal-footer {
		border-top: 0;
	}
}

.modal-body.modal-chart .nav-tabs {
	&>li.active a, &>li.toggle-on a {
		background: @color-green !important;
	}
	&>li.active a, &>li.toggle-on a, &>li a {
		padding: 0 18px;
	}
}

#date-range-label {
	margin-left: 30px;
}

#charts-wrapper .progress {
	margin: 15px;
}
