.eg_wb-slots {
	margin-left: auto;
	margin-right: auto;
	max-width: 350px;

	.row {
		border-top: 1px solid @color-green;
		line-height: 32px;
		touch-action: manipulation;
    	cursor: pointer;
		color: @color-white;
	}

	.selected {
		color: #777;
	}

	.empty {
		color: @color-gray;
	}

	.selection {
		font-size: 20px;
	}

	.row:last-child {
		border-bottom: 1px solid @color-green;
	}
}

.validation-message span {
	font-size: 70%;
	color: @color-red;
	font-weight: bold;
	background-color: @color-white;
	padding: 1px 2px;
}

button.btn-eg_wb {
	border: none !important;
	color: #777 !important;
	padding: 0 2px;
	font-size: 75%;
	float: right;
	line-height: 30px;
	vertical-align: center;
}

.row.eg_wb {
	margin-left: auto;
	margin-right: auto;
	max-width: 400px;
}

input.eg_wb-input {
	color: #777;
}

.eg_wb-input,
.eg_wb-name-input {
	max-width: 50px;
	height: 30px !important;
}

@media screen and (max-width: 400px) {
	.eg_wb-degrees-buttons .btn {
		margin-left: auto;
		margin-right: auto;
	}
}
