@defaulttilesize: 128;
@defaulttextsize: 1rem;

@keyframes pump-spin {
	to { transform: rotate(1turn); }
}

.tilemap {
	margin: 0 auto;
}

.pumptile {
	border: 1px rgba(255,255,255,0.15) dashed;
	font-size: @defaulttextsize;
	height: @defaulttilesize * 1px;
	position: relative;
	width: @defaulttilesize * 1px;

	&[class*="tile-"] {
		background-image: url(@spritesheet-image);
	}

	&.tile-text {
		background-image: none;

		.tile-dial {
			display: none;
		}
	}

	p[class*="tile-"] {
		position: absolute;
	}

	.tile-label {
		font-size: 1.8em;
		margin-top: 0;
		top: 0;
		text-align: center;
		width: 100%;
	}

	.tile-description, .tile-dial {
		margin: 0;
		text-align: center;
		width: 100%;
	}

	.tile-description {
		color: @color-green;
		bottom: 24%;
	}

	.tile-dial {
		color: @color-white;
		bottom: 6%;
	}

	&.tile-meter-pump::after {
		background: url(/assets/images/pump-wheel.svg);
		background-size: contain;
		content: " ";
		height: 28px;
		left: 50px;
		position: absolute;
		top: 45px;
		width: 28px;
	}

	&[data-value="1"].tile-meter-pump::after {
		animation: pump-spin 3s infinite linear;
	}
}

.tilebox > .pumptile.tile-meter-pump::after {
	background: none;
}

.sized(@size) {
	.tiles-@{size} .pumptile {
		height: @size * 1px;
		font-size: (@size / @defaulttilesize) * @defaulttextsize;
		width: @size * 1px;

		&.tile-meter-pump::after {
			//
		}
	}
}

.sized(64);
.sized(128);
.sized(256);

.sizedsprite(@sprite, @kind, @size) when (@size = @defaulttilesize) {
	.tiles-@{size} .pumptile.tile-@{kind},
	.pumptile.tile-@{kind} {
		.sprite-position(@sprite);
	}
}

.sizedsprite(@sprite, @kind, @size) when not (@size = @defaulttilesize) {
	.tiles-@{size} .pumptile.tile-@{kind} {
		.sprite-position(@sprite);
	}
}

.tilesprites(@sprites, @i: 1) when (@i <= length(@sprites)) {
	@sprite: extract(@sprites, @i);
	@sprite-name: extract(@sprite, 10);
	@sprite-kind: replace(@sprite-name, "_.+$", "");
	@sprite-size: replace(@sprite-name, "^.+_", "");
	.sizedsprite(@sprite, @sprite-kind, @sprite-size);

	.tilesprites(@sprites, @i + 1);
}

.tilesprites(@spritesheet-sprites);

.tile-output-right { }
.tile-output-right-1 { }

@keyframes ora {
	0%   { .pumptile.tile-output-right; }
	25%  { .pumptile.tile-output-right-1; }
	50%  { .pumptile.tile-output-right-2; }
	75%  { .pumptile.tile-output-right-3; }
	100% { .pumptile.tile-output-right-4; }
}

&[data-value="1"].tile-meter-output-right {
	animation: ora 1s infinite steps(1);
}

.tile-meter-output-right .tile-value {
	display: none;
}
