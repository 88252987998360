/* Spinning animation */
@keyframes spin-clockwise {
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
}

@keyframes spin-anticlockwise {
	from { transform: rotate(360deg); }
	to { transform: rotate(0deg); }
}

/* Alarmed animation */
@keyframes fader {
	0% { opacity: 1.0; }
	50% { opacity: 0.3; }
	100% { opacity: 1.0; }
}

/* Watering animation */
@keyframes scrolling-background {
	from { background-position: 0 100%; }
	to { background-position: 0 0; }
}

/* Pump spin animation */
@keyframes pumptile-spin {
	to { transform: rotate(1turn); }
}


.view-dashboard-tile {
	h3.panel-title {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		color: White;
	}

	/* Animation container */
	.animation-container {
	    position: absolute;
	    top: auto;
	    left: auto;
	    width: 154px;
	    height: 154px;
	}

	.alarmed {
		animation: fader 3s infinite ease-out;
	}

	/* Spinning circle (inner circle) */
	.maskedCircle {
	    width: 154px;
	    height: 154px;
	    border-radius: 77px;
	}

	.running .maskedCircle {
	    border: 8px solid #208040;
	}

	.running-water .maskedCircle {
		border: 8px solid #08C;
	}

	.alarmed .maskedCircle {
	    border: 8px solid #ff2000;
	}

	/* Spinning circle mask */
	.running .mask, .running-water .mask {
	    width: 77px;
	    height: 77px;
	    overflow: hidden;
	}

	/* Spinner */
	.spinner {
	    position: absolute;
	    left: 0px;
	    top: 0px;
	    width: 154px;
	    height: 154px;
	}

	.spinner-clockwise {
		animation: spin-clockwise 5s infinite linear;
	}

	.spinner-anticlockwise {
		animation: spin-anticlockwise 5s infinite linear;
	}

	.pivot-widget {
		width: 154px;
		height: 154px;
		margin: auto;
	}

	.installation-tile {
		cursor: pointer;
	}

	.animation-container > .glyphicon {
		font-size: 100px;
		margin: 22px 0;
		color: #ccc;
	}

	.pump-running {
		animation: pumptile-spin 5s infinite linear;
	}
}
