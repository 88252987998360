@main-control-height: 91px;
@main-control-icon-size: 33px;


.installation-name {
	margin: 20px 0 20px 0;
	padding: 0;
	padding-right: 30px;
}
.installation-section {
	margin-top: 48px;
}
.installation-section > h4 {
	margin-top: 24px;
}

dd, dt {
	padding: 8px 4px;
}

.dl-horizontal-always dt {
	float: left;
	width: 50%;
	clear: left;
	text-align: right;
}

.dl-horizontal-always dd {
	margin-left: 50%;
}

.map {
	margin-left: 1em;
	margin-right: 1em;
}

.aux-ctrl {
	padding-bottom: 2em;
}

.compact {
	padding: 6px;
}

/* Pivot controls
--------------------- */

.pivot-controls .pivot-control:first-child .main-box {
	border-top: 2px solid @color-green;
}

.pivot-control {
	color: #fff;
	clear: both;

	.heading {
		color: @color-green;
		display: inline;
		float: right;
		text-align: right;
		line-height: @main-control-height - 4;
		font-size: 16px;
	}

	.value-button {
		float: right;
		display: inline;
		background-color: #393536;
		border: 2px solid @color-green;
		border-radius: 5px;
		width: 80px;
		height: 60px;
		margin-top: 18px;
		margin-left: 1em;
		vertical-align: middle;
		text-align: center;
		line-height: 60px;
		color: #fff;
		font-size: 25px;
	}

	.icon {
		margin-top: (@main-control-height - @main-control-icon-size - 1) / 2;
		width: @main-control-icon-size;
		height: @main-control-icon-size;
		position: absolute;
	}

	.status .icon {
		background: url('/assets/images/pivot/status.png');
	}

	.water .icon {
		background: url('/assets/images/pivot/water_on_off.png');
	}

	.pivot-speed .icon {
		background: url('/assets/images/pivot/speed.png');
	}

	.water-depth .icon {
		background: url('/assets/images/pivot/depth.png');
	}

	.SIS .icon {
		background: url('/assets/images/pivot/SIS.png');
	}

	.alarms.has-alarms .icon {
		background: url('/assets/images/pivot/alarm_on.png');
	}

	.alarms.are-acknowledged .icon{
		background: url('/assets/images/pivot/alarm_acknow.png');
	}

	.alarms .icon {
		background: url('/assets/images/pivot/alarm_off.png');
	}

	.charts .icon {
		background: url('/assets/images/pivot/chart.png');
	}

	.btn-warning {
		border-color: @color-red !important;
		cursor: pointer !important;
		pointer-events: auto !important;
	}
}

.pump-setpoints.firstpumpsetpoints:before {
	content: "";
	float: left;
	width: 90%;
	line-height: @main-control-height - 2;
	border-top: 2px solid @color-green;
}

.main-box {
	float: left;
	width: 90%;
	height: @main-control-height;
	line-height: @main-control-height - 2;
	border-bottom: 2px solid @color-green;

	.heading-text {
		float: left;
	}
}

.units-box {
	float: left;
	width: 10%;
	height: @main-control-height;
	line-height: @main-control-height;
	padding-left: 0.5em;
	font-size: 17px;
}

.vri-plan-panel:first-child {
	border-right: 2px solid @color-green;
}
.vri-plan-panel {
	border-top: 2px solid @color-green;
	padding: 48px 0;
}
.vri-label {
	margin: 0;
}

.vri-action-btn {
	width: 230px;
}

.pivot-r1-main-controls {
	margin-top: 95px;
	@media screen and (max-width: 1199px) {
		/*margin-top: 60px;*/
	}
	@media screen and (max-width: 1023px) {
		/*margin-top: 110px;*/
	}
	@media screen and (max-width: 991px) {
		/*margin-top: 100px;*/
	}
	@media screen and (min-width: 1448px) {
		margin-top: 65px;
	}
	@media screen and (min-width: 1828px) {
		margin-top: 95px;
	}
}

/* alarms tab
--------------------- */

#alarms-tab, #alarms-summary-tab, #events-tab {
	margin-top: 20px;
}

/* AUX tab
--------------------- */

#aux-tab {
	clear: both;
}

.aux-control {
	border-top: 2px solid @color-green;
	color: @color-white;

	height: 80px;
	line-height: 80px;

	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	overflow: hidden !important;

	.status-text {
		float: right;
		margin-right: 36px;
	}
	.indicator {
		width: 40px !important;
		float: right !important;
	}
	.pulse {
		margin-top: 22px !important;
		margin-left: -10px !important;
		margin-right: 0 !important;
	}
	.btn {
		margin: -4px 5px 0px 0px !important;
	}
	.aux-out-options {
		float: right;

		@media screen and (max-width: 767px) {
			margin-left: -10px;
		}

		@media screen and (min-width: 768px) {
			text-align: right;
		}
	}
}
