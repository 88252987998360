/* theme colors, used throughout SCADAfarm */
@color-red: #bf1e2e;
@color-amber: #d48a38;
/*@color-green: #95BD4E;*/
@color-green: #41ad49;
@color-gray: #666;
@color-dark-gray: #444;
@color-blue: #08C;
@color-white: #fefefe;
@color-nav-primary: #222;

.text-green {
	color: @color-green;
}

.text-white {
	color: @color-white;
}
