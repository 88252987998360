.app-store {
	padding-top: 30px;
}

.well > h2, .well > h1 {
	margin-top: 0;
}

.control-display-combo p.well.well-sm.scadafarm-feedback.text-center {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border-bottom: 0;
}
.control-display-combo .btn-group-justified .btn-group .btn {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

p.well.well-sm.scadafarm-feedback {
	padding-top: 12px;
	padding-bottom: 11px;
	margin-bottom: 0;
}

@media screen and (max-width: 560px) {
	.well {
	margin: 0;
	}
}

.well-dark {
	background-color: #080808;
	background-color: rgba(10, 10, 10, 0.8);
	color: #eee;
}

.container .text-muted {
	margin: 15px 0;
}

#footer > .container {
	padding-right: 15px;
	padding-left: 15px;
}

.container-full-width {
	width: 100%;
}

.padded {
	padding: 32px;
}
#intercom-container .intercom-active-admins, #intercom-container .intercom-last-active {
	display: none !important;
}
